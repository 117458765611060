import { useState, useEffect } from 'react'
import { User } from '../adapter/auth0/auth0'

export async function fetchUser(cookie = '') {
  if (typeof window !== 'undefined' && (window as any).__user) {
    return (window as any).__user
  }

  const res = await fetch(
    '/api/auth0/me',
    cookie
      ? {
          headers: {
            cookie,
          },
        }
      : {}
  )

  if (!res.ok) {
    delete (window as any).__user
    return null
  }

  const json = await res.json()
  if (typeof window !== 'undefined') {
    ;(window as any).__user = json
  }
  return json
}

export function useFetchUser({ required }: any = {}) {
  const [loading, setLoading] = useState(() => !(typeof window !== 'undefined' && (window as any).__user))
  const [user, setUser] = useState<User | null>(() => {
    if (typeof window === 'undefined') {
      return null
    }

    return (window as any).__user || null
  })

  useEffect(() => {
    if (!loading && user) {
      return
    }
    setLoading(true)
    let isMounted = true

    fetchUser().then((user) => {
      // Only set the user if the component is still mounted
      if (isMounted) {
        // When the user is not logged in but login is required
        if (required && !user) {
          window.location.href = '/api/auth0/login'
          return
        }
        setUser(user)
        setLoading(false)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  return { user, loading }
}
