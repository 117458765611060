import { i18n } from '@lingui/core'
import { messages as deMessages } from '@/locales/de/messages.js'
import { messages as frMessages } from '@/locales/fr/messages.js'
import { de as dePlurals, fr as frPlurals } from 'make-plural/plurals'

const configureLingui = () => {
  i18n.load('de', deMessages)
  i18n.load('fr', frMessages)
  i18n.loadLocaleData('de', { plurals: dePlurals })
  i18n.loadLocaleData('fr', { plurals: frPlurals })
  i18n.activate('de')
  return i18n
}

const lingui = configureLingui()

export default lingui
