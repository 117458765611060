import Snackbar from '@/components/snackbar'
import { FleetOwner } from '@/domain/fleet/types'
import { Garage } from '@/domain/garage/types'
import Head from 'next/head'
import Header from './header'
import styles from './page.module.css'

type Props = {
  title: string
  garage?: Garage
  fleetOwner?: FleetOwner
  children: React.ReactNode
}

const Page = ({ title, garage, fleetOwner, children }: Props) => (
  <div>
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/public/favicon/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/public/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/public/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/public/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/public/site.webmanifest"></link>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
    </Head>

    <Snackbar />

    <Header garage={garage} fleetOwner={fleetOwner} />

    <main className={styles.pageMain}>{children}</main>
  </div>
)

export default Page
