interface Props {
  height: number
  width: number
}
const Logo = ({ height, width }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 297.73343 107.44702"
      version="1.1"
      id="svg5"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="layer1" transform="translate(-268.3176,-54.505305)">
        <g id="g97" transform="matrix(0.35277778,0,0,0.35277778,172.95736,-31.310645)">
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 399.59766,311.31641 -0.0234,-0.0234 z m 0,0"
            id="path55"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 320.37891,417.22656 32.9375,-32.93359 c 15.46875,5.37109 33.43359,1.80078 45.90234,-10.66797 11.59766,-11.59766 15.49609,-27.95703 11.66016,-42.63281 l -27.14844,27.14453 -25.45313,-9.78125 -5.52343,-21.19531 27.14843,-27.14844 c -14.67968,-3.83594 -31.03906,0.0625 -42.63672,11.66015 -10.68359,10.6836 -14.83203,25.41016 -12.42187,39.13672 l -34.32813,34.32813 c -17.94921,-33.3086 -12.64843,-76.01953 15.77344,-104.44141 34.61328,-34.61719 90.42969,-34.94531 124.64063,-0.73437 34.21093,34.21093 33.88281,90.02734 -0.73438,124.64062 -29.97265,29.97656 -75.83984,34.23438 -109.8164,12.625 m 47.86328,-173.96875 c -54.07813,0 -97.92969,43.84766 -97.92969,97.9375 0,30.17969 11.04297,63.39453 25.71484,94.19531 11.29297,23.72266 24.73828,46.01172 36.94922,64.39454 19.15625,28.83593 35.26563,48.04687 35.26563,48.04687 0,0 97.9375,-117.33203 97.9375,-206.63672 0,-54.08984 -43.84375,-97.9375 -97.9375,-97.9375"
            id="path57"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 553.03125,373.91016 c 0,3.46875 -2.76172,6.23046 -6.35547,6.23046 h -40.32422 v 28.37891 h 37.69141 c 3.66015,0 6.42187,2.63281 6.42187,6.16406 0,3.40235 -2.89062,6.23047 -6.42187,6.23047 h -37.69141 v 30.94922 c 0,3.78516 -2.89062,6.67578 -6.61328,6.67578 -3.46875,0 -6.48437,-3.14844 -6.48437,-6.67578 v -74.09766 c 0,-5.52343 3.59375,-10.08203 10.27343,-10.08203 h 43.14844 c 3.66016,0 6.35547,2.69532 6.35547,6.22657"
            id="path59"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 581.47266,372.88281 v 79.04297 c 0,3.66016 -2.75782,6.61328 -6.41797,6.61328 -3.53125,0 -6.42188,-2.95312 -6.42188,-6.61328 v -79.04297 c 0,-3.72265 2.82422,-6.74219 6.42188,-6.74219 3.53125,0 6.41797,3.01954 6.41797,6.74219"
            id="path61"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 613.12891,419.37109 h 31.52343 c 1.16016,0 1.60938,-0.44922 1.67188,-1.73437 0.70703,-10.53125 -5.97266,-17.46485 -16.2461,-17.46485 -11.55468,0 -17.14062,7.38672 -16.94921,19.19922 m 45.20312,-1.98828 v 3.85156 c 0,4.6875 -2.50391,7.89844 -8.28125,7.89844 h -36.92187 v 1.92578 c 0,10.65625 7.51171,16.3711 17.91406,16.3711 5.71484,0 10.07812,-0.96094 13.54687,-2.56641 1.80078,-0.83594 3.14453,-1.28516 4.4336,-1.28516 3.08203,0 5.71093,2.56641 5.71093,5.77735 0,2.12109 -1.08984,4.23828 -4.10937,5.71484 -4.49219,2.1836 -11.42969,4.10938 -19.96875,4.10938 -17.84766,0 -28.63672,-11.30078 -29.79297,-27.60938 -0.32031,-4.8125 -0.25781,-11.8125 0.0664,-15.85937 1.28515,-16.5 12.90234,-26.71094 29.08593,-26.71094 17.14454,0 28.31641,11.42969 28.31641,28.38281"
            id="path63"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 686.39062,419.37109 h 31.52735 c 1.15625,0 1.60547,-0.44922 1.66797,-1.73437 0.70703,-10.53125 -5.96875,-17.46485 -16.2461,-17.46485 -11.55468,0 -17.14062,7.38672 -16.94922,19.19922 m 45.20313,-1.98828 v 3.85156 c 0,4.6875 -2.50391,7.89844 -8.28125,7.89844 h -36.92188 v 1.92578 c 0,10.65625 7.51172,16.3711 17.91407,16.3711 5.71484,0 10.08203,-0.96094 13.55078,-2.56641 1.79687,-0.83594 3.14453,-1.28516 4.42969,-1.28516 3.08203,0 5.71093,2.56641 5.71093,5.77735 0,2.12109 -1.08984,4.23828 -4.10937,5.71484 -4.49219,2.1836 -11.42578,4.10938 -19.96875,4.10938 -17.84766,0 -28.63281,-11.30078 -29.79297,-27.60938 -0.32031,-4.8125 -0.25391,-11.8125 0.0664,-15.85937 1.28515,-16.5 12.90625,-26.71094 29.08593,-26.71094 17.14453,0 28.31641,11.42969 28.31641,28.38281"
            id="path65"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 761.90234,380.39844 v 9.75781 h 18.29688 c 3.33984,0 6.23047,2.50391 6.23047,6.16406 0,3.21094 -2.89063,5.84375 -6.23047,5.84375 h -18.29688 v 34.35156 c 0,6.42188 2.82422,10.46485 9.69532,10.46485 2.95312,0 5.83984,-0.57813 8.66796,-1.66797 0.96094,-0.25781 1.92579,-0.51563 2.82422,-0.44922 3.27344,0.19141 5.52344,2.82422 5.52344,5.77734 0,2.76172 -1.54297,4.625 -4.17578,5.84375 -3.46875,1.60547 -8.47266,2.69532 -13.80469,2.69532 -15.15234,0 -21.57422,-9.94922 -21.57422,-22.21485 v -56.5664 c 0,-3.72657 2.76172,-6.74219 6.35938,-6.74219 3.66015,0 6.48437,3.01562 6.48437,6.74219"
            id="path67"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 817.69922,380.01172 v 33.39062 h 21.44531 c 12.77734,0 17.91406,-6.87109 17.91406,-16.69531 0,-11.81641 -6.80468,-16.69531 -21.38281,-16.69531 z m 52.52344,16.5664 c 0,18.55469 -13.09766,29.14844 -31.07813,29.14844 h -21.44531 v 26.07031 c 0,3.59375 -3.08203,6.74219 -6.55078,6.74219 -3.59375,0 -6.61328,-3.14844 -6.61328,-6.74219 V 377.1875 c 0,-5.13672 3.08203,-9.50391 9.82421,-9.50391 h 25.875 c 16.2461,0 29.98829,10.59375 29.98829,28.89453"
            id="path69"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 930.32031,431.57031 c 0.44922,-5.26562 0.38672,-10.33594 0,-14.96094 -0.83594,-8.98828 -6.99609,-15.15234 -16.6914,-15.15234 -9.76172,0 -15.92579,6.16406 -16.69532,15.15234 -0.45312,4.625 -0.51562,9.69532 0,14.96094 0.76953,9.11719 6.9336,15.08985 16.69532,15.08985 9.69531,0 15.85546,-5.97266 16.6914,-15.08985 m -46.10156,1.28516 c -0.51172,-5.39453 -0.51172,-12.19922 0,-17.59375 C 885.63281,400.04297 897.31641,389 913.62891,389 c 16.24218,0 27.99218,11.04297 29.40625,26.26172 0.51171,5.39453 0.51171,12.19922 0,17.59375 -1.41407,15.08984 -13.16407,26.32422 -29.40625,26.32422 -16.3125,0 -27.9961,-11.23438 -29.41016,-26.32422"
            id="path71"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 975.65234,396.19141 v 55.73437 c 0,3.72266 -2.69531,6.61328 -6.48437,6.61328 -3.66016,0 -6.35938,-2.82422 -6.35938,-6.61328 v -55.73437 c 0,-3.66016 2.76172,-6.61329 6.42188,-6.61329 3.59765,0 6.42187,2.95313 6.42187,6.61329 m 0.96485,-23.11329 c 0,4.10938 -3.33985,7.57422 -7.44922,7.57422 -4.23828,0 -7.25781,-3.46484 -7.25781,-7.57422 0,-4.04687 3.01953,-7.32031 7.25781,-7.32031 4.10937,0 7.44922,3.27344 7.44922,7.32031"
            id="path73"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 1054.6953,416.09766 v 35.82812 c 0,3.72266 -2.6367,6.61328 -6.4219,6.61328 -3.4687,0 -6.4218,-2.89062 -6.4218,-6.61328 v -35.12109 c 0,-10.53125 -6.9336,-15.15625 -15.4102,-15.15625 -6.8086,0 -12.8437,2.12109 -15.8594,4.04687 v 46.23047 c 0,3.72266 -2.7617,6.61328 -6.4218,6.61328 -3.4649,0 -6.48442,-2.89062 -6.48442,-6.61328 v -50.78906 c 0,-2.69531 0.76953,-3.91797 2.50392,-5.20313 3.8515,-2.69531 13.0976,-6.93359 26.582,-6.93359 18.4922,0 27.9336,9.82422 27.9336,27.09766"
            id="path75"
          />
          <path
            fillRule="nonzero"
            fill="#ffffff"
            fillOpacity="1"
            d="m 1087.5703,380.39844 v 9.75781 h 18.2969 c 3.3398,0 6.2305,2.50391 6.2305,6.16406 0,3.21094 -2.8907,5.84375 -6.2305,5.84375 h -18.2969 v 34.35156 c 0,6.42188 2.8242,10.46485 9.6953,10.46485 2.9532,0 5.8399,-0.57813 8.668,-1.66797 0.9609,-0.25781 1.9258,-0.51563 2.8242,-0.44922 3.2735,0.19141 5.5235,2.82422 5.5235,5.77734 0,2.76172 -1.543,4.625 -4.1758,5.84375 -3.4688,1.60547 -8.4727,2.69532 -13.8047,2.69532 -15.1524,0 -21.5742,-9.94922 -21.5742,-22.21485 v -56.5664 c 0,-3.72657 2.7617,-6.74219 6.3593,-6.74219 3.6602,0 6.4844,3.01562 6.4844,6.74219"
            id="path77"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
