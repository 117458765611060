import { useContext } from 'react'
import NotificationContext from '@/context/notification-context'

const Snackbar = () => {
  const notification = useContext(NotificationContext)

  if (notification?.notification !== 'NONE') {
    let bgColor = ''

    switch (notification?.notification) {
      case 'SUCCESS':
        bgColor = 'green'
        break
      case 'ERROR':
        bgColor = 'red'
        break
    }

    return (
      <>
        <div className="container">
          <div className="text">{notification?.notificationText}</div>
        </div>
        <style jsx>{`
          .container {
            margin: 1rem;
            position: absolute;
            text-align: center;
            background-color: ${bgColor};
            height: 3rem;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1000;
            border-radius: 6px;
          }
          .text {
            color: white;
            position: relative;
            opacity: 1;
            top: 50%;
            transform: translateY(-50%);
          }
        `}</style>
      </>
    )
  } else {
    return null
  }
}

export default Snackbar
