import getConfig from 'next/config'

const isProductionSystem = process.env.NEXT_PUBLIC_SYSTEM === 'production'
const isDevelopmentSystem = !isProductionSystem
const { serverRuntimeConfig } = getConfig() || { serverRuntimeConfig: { PROJECT_ROOT: '' } } // OR case because of Jest (undefined)

const getBaseURL = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000/'
  } else {
    return process.env.BASE_URL
  }
}

const galliker = {
  auftraggeberId: '483445',
  sendungsnummerPrefix: 92305,
}

export { isProductionSystem, isDevelopmentSystem, getBaseURL, galliker }

export const PROJECT_ROOT = serverRuntimeConfig.PROJECT_ROOT
