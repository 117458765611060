import { useState, createContext } from 'react'

enum NotificationStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  None = 'NONE',
}

const defaultTimeOuut = 5000

type NotificationContextType = {
  notification: NotificationStatus
  notificationText: string | null
  success: (text: string, timeout?: number) => void
  error: (text: string, timeout?: number) => void
  clear: () => void
}

const NotificationContext = createContext<NotificationContextType | null>(null)
NotificationContext.displayName = 'NotificationContext'

const NotificationProvider = ({ children }: any) => {
  const [notification, setNotification] = useState<NotificationStatus>(NotificationStatus.None)
  const [notificationText, setNotificationText] = useState<string | null>(null)

  return (
    <NotificationContext.Provider
      value={{
        notification: notification,
        notificationText: notificationText,
        success: (text, timeout) => {
          setNotificationText(text)
          setNotification(NotificationStatus.Success)
          setTimeout(() => {
            setNotification(NotificationStatus.None)
          }, timeout ?? defaultTimeOuut)
        },
        error: (text, timeout) => {
          setNotificationText(text)
          setNotification(NotificationStatus.Error)
          setTimeout(() => {
            setNotification(NotificationStatus.None)
          }, timeout ?? defaultTimeOuut)
        },
        clear: () => setNotification(NotificationStatus.None),
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export { NotificationProvider }

export default NotificationContext
